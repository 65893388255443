<template>
  <v-container fluid fill-height class="pa-0 overflow-y-hidden">
    <initialization-check>
      <v-row no-gutters style="height: 100%">
        <v-col class="d-flex" style="height: 100%">
          <conversations
            @newMessage="newMessageForm = true"
            :styles="'height: 93%;'"
          />
        </v-col>
        <v-divider vertical />
        <v-col
          class="d-flex flex-column"
          style="height: 100%; contain: content"
        >
          <div
            class="overflow-y-auto d-flex flex-grow-1 flex-shrink-1"
            style="height: 0px"
          >
            <v-row
              v-if="!currentConversation"
              align="center"
              justify="center"
              style="height: 100%"
            >
              <div class="title">Select a Conversation to see Messages</div>
            </v-row>
            <FirebaseMessages hide-toolbar v-else>
              <div
                v-if="!pickedUp"
                class="px-5 py-2 my-0 justify-center align-center d-flex"
                style="width: 100%"
              >
                <v-btn color="primary" @click="acceptConversation">
                  Pick Up
                </v-btn>
              </div>
            </FirebaseMessages>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="newMessageForm" max-width="500">
        <NewMessageForm
          @onBack="newMessageForm = !newMessageForm"
          @finished="newMessageForm = false"
        />
      </v-dialog>
      <v-dialog width="unset" v-model="dialog">
        <UpdateLog :changes="changes" @close="dialog = !dialog" />
      </v-dialog>
    </initialization-check>
  </v-container>
</template>

<script>
import { messages } from '../sharedPlugin';
import NewMessageForm from '../components/messages/NewMessageForm.vue';
import UpdateLog from '../components/UpdateLog.vue';
import FirebaseMessages from '../components/messages/FirebaseMessages.vue';
import InitializationCheck from '../components/messages/InitializationCheck.vue';
import Conversations from '../components/messages/Conversations.vue';

export default {
  components: {
    NewMessageForm,
    UpdateLog,
    FirebaseMessages,
    InitializationCheck,
    Conversations
  },
  data() {
    return {
      newMessageForm: false,
      dialog: false,
      changes: ''
    };
  },
  computed: {
    ...messages.mapComputed([
      'selectedConversation',
      'currentConversation',
      'loading'
    ]),
    pickedUp() {
      return this.currentConversation?.pickedUp;
    }
  },
  methods: {
    ...messages.mapMethods(['selectConversationAsync', 'acceptConversation'])
  },
  mounted() {
    if (!this.selectedConversation && this.$route.params.conversationId) {
      this.selectConversationAsync(
        Number(this.$route.params.conversationId)
      ).catch(() => {
        this.$router.push({ path: '/messages' }).then(() =>
          this.$store.commit('showSnackbar', {
            message: 'Unable to find Conversation',
            color: 'red'
          })
        );
      });
    }
  }
};
</script>

<style>
html {
  overflow-y: auto;
}
.theme--light.v-application {
  background: #f5f5f5;
}
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td,
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > td,
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > td {
  height: 80px;
}
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
  background: #f5f5f5;
}
</style>
